import React from 'react'
import '@/assets/css/order.css'
import { PullStatus } from 'antd-mobile/es/components/pull-to-refresh'

const tabItems = [
    { key: 'all', title: '全部订单' },
    // { key: '0', title: '待付款' },
    { key: '1234', title: '已付款' },
    { key: '3', title: '待评价' },
    { key: '4', title: '已完成' },
    { key: '-123', title: '退款' },
]

const statusRecord: Record<PullStatus, string> = {
    pulling: '用力拉',
    canRelease: '松开吧',
    refreshing: '玩命加载中...',
    complete: '好啦',
}

const Index = () => {
    //默认选项卡
    var [activeIndex, setActiveIndex] = React.useState(0)

    var [status, SetStatus] = React.useState('all')
    var [page, SetPage] = React.useState(1)
    var [list, SetList] = React.useState([])
    var [hasMore, SethasMore] = React.useState(true)
    var ref: any = React.useRef(null);

    React.useEffect(() => {
        onRefresh()
    }, [status])

    // 下拉刷新
    const onRefresh = async () => {
        SetPage(1)
        SetList([])
        SethasMore(true)
    }

    //上拉加载
    const loadMore = async () => {
        SetPage(page + 1)
        await ListData()
    }

    //请求数据
    const ListData = async () => {
        //组装数据
        var data = {
            page: page,
            status: status,
            busid: React.Business.id
        }

        var result = await React.HTTP.post('/order/index', data)

        if (result.code == 0) {
            //没有更多数据
            SethasMore(false)
            return false
        }

        if (result.data.length < 10) {
            SethasMore(false)
        }

        SetList(list.concat(result.data))
    }


    const clickMenu = async (key: any) => {
        SetStatus(key)
        SetPage(1)
        SetList([])
        SethasMore(true)
        // await ListData()
    }

    // const Button = (status: any) => {
    //     switch (status) {
    //         case '1':
    //             return <button className="item_cancel" onClick={checkOut}>取消订单</button>
    //         case '3':
    //             return <button className="item_comfig" onClick={checkOut}>前往评价</button>
    //         case '-3':
    //             return <button className="item_comfig" onClick={checkOut}>重新申请</button>
    //         default:
    //             break;
    //     }
    // }


    return (
        <>
            <div className='top'><React.UI.NavBar onBack={() => React.Navigate(-1)}>房间订单</React.UI.NavBar></div>

            <div className='ordermenu'>
                <React.UI.Tabs
                    activeKey={tabItems[activeIndex].key}
                    onChange={async (key: any) => {
                        const index = tabItems.findIndex(item => item.key === key)
                        setActiveIndex(index);
                        await clickMenu(key);
                    }}
                >
                    {tabItems.map(item => (
                        <React.UI.Tabs.Tab title={item.title} key={item.key} />
                    ))}
                </React.UI.Tabs>
            </div>
            <div className="order_list">
                {/* 下拉刷新 */}
                <React.UI.PullToRefresh
                    onRefresh={onRefresh}
                    renderText={(status: PullStatus) => {
                        return <div>{statusRecord[status]}</div>
                    }}
                >
                    <React.UI.List >
                        {list.map((item: any, key) => (
                            <React.UI.List.Item arrow={false} key={key}>
                                <div className="item">
                                    <div className="item_top" onClick={() => React.Navigate(`/order/info?orderid=${item.id}`)}>
                                        <p>{item.room.name}</p>
                                        <div className="top_tag">{item.status_text}</div>
                                    </div>
                                    <div className="house" onClick={() => React.Navigate(`/order/info?orderid=${item.id}`)}>
                                        <div className="item_swipers">
                                            <img src={item.room.thumb_text} alt="" />
                                        </div>
                                        <div className="item_times">
                                            <div>
                                                <div>{item.starttime_info}</div>
                                                <div>{item.starttime_week} 15:00</div>
                                            </div>
                                            <div>
                                                <div>共{item.night_text}晚</div>
                                                <div className="item_right">
                                                    <img src="./assets/images/right1.png" alt="" />
                                                </div>
                                            </div>
                                            <div>
                                                <div>{item.endtime_info}</div>
                                                <div>{item.endtime_week} 12:00</div>
                                            </div>
                                            <div>
                                                <div className="item_pay">订单总价</div>
                                                <div className="item_price">￥{item.price}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="item_bar">
                                        {Button(item.status)}
                                    </div> */}
                                </div>
                            </React.UI.List.Item>
                        ))}
                    </React.UI.List>

                    <React.UI.InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0} />
                </React.UI.PullToRefresh>
            </div>
        </>
    )
}

export default Index
