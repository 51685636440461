import React, { useState } from 'react'
import type { FC } from 'react'
import '@/assets/css/login.css'
import { ImageUploadItem } from 'antd-mobile/es/components/image-uploader'


const Profile = () => {
    //文件路径列表
    const [fileList, setFileList] = useState<ImageUploadItem[]>([
        {
            url: React.Business.avatar_text
        }
    ])

    //文件数据
    var [filedata, setFiledata] = useState<File>()

    // 文件上传
    function mockUpload(file: File) {
        setFiledata(file)
        return {
            url: URL.createObjectURL(file),
        }
    }

    const LimitCount: FC = () => {
        const maxCount = 1


        return (
            <React.UI.ImageUploader
                value={fileList}
                onChange={setFileList}
                upload={mockUpload}
                multiple
                maxCount={1}
                showUpload={fileList.length < maxCount}
            // onCountExceed={exceed => {
            //     Toast.show(`最多选择 ${maxCount} 张图片，你多选了 ${exceed} 张`)
            // }}
            />
        )
    }

    const profile = async (values: any) => {
        if (filedata) {
            values.avatar = filedata
        } else {
            values.avatar = '';
        }
        values.id = React.Business.id

        var result: any = await React.HTTP.post('/business/profile', values, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })

        if (result.code) {
            React.UI.Toast.show({
                icon: 'success',
                content: result.msg,
                afterClose: () => {
                    React.Cookie.save('business',result.data)
                    React.Business = React.Cookie.load('business') ? React.Cookie.load('business') : {};
                    React.Navigate(-1)
                    return false
                }
            })

        } else {
            React.UI.Toast.show({
                icon: 'fail',
                content: result.msg
            })
            return false
        }
    }


    return (
        <>
            <div className='top'><React.UI.NavBar onBack={() => React.Navigate(-1)}>基本资料</React.UI.NavBar></div>

            <div className='login'>
                <React.UI.Form
                    initialValues={{ mobile: React.Business.mobile, nickname: React.Business.nickname }}
                    onFinish={profile}
                    name='login'
                    footer={
                        <React.UI.Button block type='submit' color='primary' size='large'>
                            修改
                        </React.UI.Button>
                    }
                >
                    <div className='avatar'>
                        <LimitCount />
                    </div>

                    <React.UI.Form.Item name='mobile' label='账号(手机号):' >
                        <React.UI.Input placeholder='请输入手机号' readOnly />
                    </React.UI.Form.Item>

                    <React.UI.Form.Item name='nickname' label='昵称:' rules={[{}]}>
                        <React.UI.Input placeholder='请输入昵称' />
                    </React.UI.Form.Item>

                    <React.UI.Form.Item name='password' label='密码:' rules={[{}]}>
                        <React.UI.Input type="password" placeholder='如需修改,请输入新密码' />
                    </React.UI.Form.Item>

                </React.UI.Form>
            </div>
        </>
    )
}

export default Profile