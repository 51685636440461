import React from 'react'
import '@/assets/css/collect.css'
import { PullStatus } from 'antd-mobile/es/components/pull-to-refresh'

const statusRecord: Record<PullStatus, string> = {
    pulling: '用力拉',
    canRelease: '松开吧',
    refreshing: '玩命加载中...',
    complete: '好啦',
}

const Collect = () => {
    let [page, SetPage] = React.useState(1)
    let [list, SetList] = React.useState([])
    let [hasMore, SethasMore] = React.useState(true)

    // 下拉刷新
    const onRefresh = async () => {
        SetPage(1)
        SetList([])
        SethasMore(true)
        // ListData()
    }

    //上拉加载
    const loadMore = async () => {
        SetPage(page + 1)
        await ListData()
    }

    //请求数据
    const ListData = async () => {
        //组装数据
        var data = {
            page: page,
            busid: React.Business.id
        }

        var result = await React.HTTP.post('/collect/index', data)

        if (result.code == 0) {
            //没有更多数据
            SethasMore(false)
            return false
        }

        if (result.data.length < 10) {
            SethasMore(false)
        }

        SetList(list.concat(result.data))
    }

    const ClickCollect = async (roomid: any) => {
        if (!React.Business.id) {
            React.error('请先登录', () => { });
            return false;
        }
        var result = await React.HTTP.post('/room/collect', { roomid, busid: React.Business.id })
        if (result.code == 1) {
            onRefresh()
        }

    }
    return (
        <>
            <div className='top'>
                <React.UI.NavBar
                    back='返回'
                    onBack={() => React.Navigate(-1)}
                >收藏列表</React.UI.NavBar>
            </div>

            <React.UI.PullToRefresh
                onRefresh={onRefresh}
                renderText={(status: PullStatus) => {
                    return <div>{statusRecord[status]}</div>
                }}>
                <React.UI.List>
                    <div className="collectlist">
                        {list.map((item: any, key) => (
                            <a >
                                <div className="item">
                                    <div className="collect">
                                        <React.Icon.HeartFill fontSize={30} color='red' onClick={() => ClickCollect(item.roomid)} />
                                    </div>
                                    <div className="images" onClick={() => { React.Navigate(`/room/info?rid=${item.room.id}`) }}>
                                        <div className="swipers">
                                            <img src={item.room.thumb_text} alt="" />
                                        </div>
                                        <div className="title">{item.room.name}</div>
                                    </div>
                                    <div>
                                        <div className="item_bot">
                                            <div className="font">
                                                <span className="price">￥{item.room.price}/晚</span>
                                                {/* <span className="market_price">￥80.00/晚</span> */}
                                            </div>
                                            <div className="item_tips">收藏时间:{item.createtime_text}</div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </React.UI.List>

                <React.UI.InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0} />
            </React.UI.PullToRefresh>
        </>
    )
}


export default Collect