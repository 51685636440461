import React from 'react'
const Menu = () => {
    const pathname = React.Router.useLocation().pathname;

    if (pathname == '/') {
        var pns = true
    } else {
        var pns = false;
    }

    return (
        <div className="footer">
            <React.Router.Link className={pns ? 'item active' : 'item'} to="/">
                <div className="item_img">
                    <img src="/assets/images/order.png" alt="" />
                </div>
                <div className="title">首页</div>
            </React.Router.Link>
            <React.Router.Link className={pns ? 'item ' : 'item active'} to="/business/index">
                <div className="item_img">
                    <img src="/assets/images/order.png" alt="" />
                </div>
                <div className="title">我的</div>
            </React.Router.Link>
        </div>
    );
}


export default Menu