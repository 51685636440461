import React, { useEffect } from 'react'
const Guest = () => {
    let [page, SetPage] = React.useState(1)
    let [list, SetList] = React.useState([])
    let [hasMore, SethasMore] = React.useState(true)

    // 下拉刷新
    const onRefresh = async () => {
        SetPage(1)
        SetList([])
        SethasMore(true)
    }

    //上拉加载
    const loadMore = async () => {
        SetPage(page + 1)
        await ListData()
    }

    //请求数据
    const ListData = async () => {

        //组装数据
        var data = {
            page: page,
            busid: React.Business.id
        }

        var result = await React.HTTP.post('/guest/index', data)

        if (result.code == 0) {
            //没有更多数据
            SethasMore(false)
            // React.error(result.msg, () => { })
            return false
        }

        SetList(list.concat(result.data))
        // console.log(list);
        // console.log(page);

        return false

    }

    const ClickGuest = async (gid: any) => {
        var result = await React.UI.Dialog.confirm({
            content: '确认是否删除'
        })

        if (!result) return false

        var result = await React.HTTP.post('/guest/del', { id: gid, busid: React.Business.id })

        if (result.code == 0) {
            React.error(result.msg, () => { })
            return false
        } else {
            React.success(result.msg, () => {
                onRefresh()
            })
            return false
        }
    }

    //封装右侧滑动的按钮数据
    const right = [
        {
            key: 'delete',
            text: '删除信息',
            color: 'danger',
        }
    ]



    return (
        <>
            <div className='top'>
                <React.UI.NavBar
                    back='返回'
                    right={(<React.Icon.AddOutline fontSize={30} onClick={() => React.Navigate('/guest/add')} />)}
                    onBack={() => React.Navigate(-1)}
                >住客列表</React.UI.NavBar>
            </div>

            <React.UI.PullToRefresh onRefresh={onRefresh}>
                <React.UI.List>
                    {list.map((item: any, key) => (
                        <React.UI.SwipeAction key={key} rightActions={right} onAction={ClickGuest.bind(this, item.id)}>
                            <React.UI.List.Item key={key} description={item.mobile} onClick={() => React.Navigate(`guest/edit?id=${item.id}`)}>
                                {item.nickname}({item.gender_text})
                            </React.UI.List.Item>
                        </React.UI.SwipeAction>
                    ))}
                </React.UI.List>

                <React.UI.InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0} />
            </React.UI.PullToRefresh>
        </>
    )
}


export default Guest