import React, { useEffect } from 'react';
import Menu from '@/components/Common/Menu'

import '@/assets/css/user.css'
// import { constants } from 'buffer';

const Index = () => {
    let [business,SetBusiness]=React.useState(React.Cookie.load('business')??{});
    // 退出
    const logout = async () => {
        var result = await React.UI.Dialog.confirm({
            content: '是否确认退出'
        })

        if (result) {
            // 删除cookie
            React.Cookie.remove('business')
            React.Business={};

            //跳转
            React.Navigate('/business/login')
        }
    }

    let [nopaid, SetNopaid] = React.useState(0)
    let [paided, SetPaided] = React.useState(0)
    let [nocomment, SetNocomment] = React.useState(0)

    const info = async () => {
        var result: any = await React.HTTP.post('/order/count',{busid:React.Business.id});
        if (result.code == 1) {
            SetNopaid(result.data.nopaid);
            SetPaided(result.data.paided);
            SetNocomment(result.data.nocomment);
            return false;
        }
    }

    useEffect(()=>{
        info();
    },[])

    return (
        <>
            <div className='top'><React.UI.NavBar back={null}>个人中心</React.UI.NavBar></div>

            {/* 头部 */}
            <div className="header">
                <div className="userinfo">
                    <div className="avatar">
                        <img src={business.avatar_text??'@/assets/images/avatar.png'} alt="" />
                    </div>
                    <div className="nickname">{business.nickname ?? business.mobile}</div>
                    {/* <div className="nickname content">这家伙很懒，什么也没写！</div> */}
                </div>
                <div className="corrugated">
                    <div className="wave-top wave-item"></div>
                    <div className="wave-middle wave-item"></div>
                    <div className="wave-bottom wave-item"></div>
                </div>
            </div>
            <div className="menu-center">
                <div className="item">
                    <div>{nopaid}</div>
                    <div className="text">待支付</div>
                </div>
                <div className="item">
                    <div>{paided}</div>
                    <div className="text">已支付</div>
                </div>
                <div className="item">
                    <div>{nocomment}</div>
                    <div className="text">待评论</div>
                </div>
            </div>
            <div className="menu-cell">
                <div className="item">
                    <React.Router.Link to="/business/profile">
                        <div className="title">个人资料</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.Link>
                </div>
                <div className="item">
                    <React.Router.Link to="/guest/index">
                        <div className="title">住客列表</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.Link>
                </div>
                <div className="item">
                    <React.Router.Link to="/order/index">
                        <div className="title">房间订单</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.Link>
                </div>
                <div className="item">
                    <React.Router.Link to="/business/collect">
                        <div className="title">我的收藏</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.Link>
                </div>
                <div className="item">
                    <React.Router.Link to="/coupon/index">
                        <div className="title">我的优惠券</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.Link>
                </div>
                <div className="item">
                    <React.Router.Link to="/business/hotelinfo">
                        <div className="title">关于我们</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.Link>
                </div>
                <div className="item">
                    <React.Router.Link onClick={logout}>
                        <div className="title">退出登录</div>
                        <div className="icon">
                            <img src="/assets/images/right.png" alt="" />
                        </div>
                    </React.Router.Link>
                </div>
            </div>
            <Menu />
        </>
    )
}

export default Index