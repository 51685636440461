import React from 'react';
import { TMap } from '@map-component/react-tmap';

import '@/assets/css/detail.css'


const Component = () => {
    //接收跳转参数
    const [searchParams] = React.Router.useSearchParams()

    let [rid, SetRID] = React.useState(searchParams.get('rid') ? searchParams.get('rid') : 0)
    let [commentList, SetComment] = React.useState([])

    let [room, SetRoom] = React.useState({
        name: '',
        price: '',
        state: '',
        thumb_text: '',
        flag_text: [],
        content: '',
        total: 0,
    })

    React.useEffect(() => {
        RoomData()
        Comment()
    }, [])

    //请求详情
    const RoomData = async () => {
        var result = await React.HTTP.post("/room/info", { rid })

        if (result.code == 0) {
            React.error(result.msg)
            return false
        }

        SetRoom(result.data)
    }

    const Comment=async()=>{
        var result = await React.HTTP.post("/room/comment", { rid })

        if (result.code == 1) {
           SetComment(result.data)
        }

    }

    const confirm = () => {
        if (!React.Business.id) {
            React.error('请先登录', () => { });
            return false
        }

        React.Navigate(`order/confirm?rid=${rid}`)
    }

    return (
        <>
            <div className='top'>
                <React.UI.NavBar
                    style={{ background: `rgb(55, 68, 134)`, color: '#fff' }}
                    back='返回'
                    onBack={() => React.Navigate(-1)}
                >{room.name}</React.UI.NavBar>
            </div>

            {room.thumb_text ? <React.UI.Image src={room.thumb_text} width={'100%'} fit="fill" /> : ""}

            <div className="detail_top">
                <div id="intro" className="intro">
                    <div className="title">{room.name}</div>
                    <div className="betwee">
                        <div className="left">
                            {room.flag_text && room.flag_text.map((flag: any, index: any) => <span key={index}>{flag}</span>)}
                        </div>
                        {/* <div className="right">分享</div> */}
                    </div>
                    {/* <div className="title">{room.content}</div> */}
                </div>

                <div id="comment" className="comment">
                    <div className="title">评价</div>
                    {/* <React.UI.List> */}
                        {commentList && commentList.map((comment: any, index: any) => (
                            <React.UI.List.Item
                                key={index}
                                prefix={
                                    <React.UI.Image
                                        src={comment.business.avatar_text}
                                        style={{ borderRadius: 20,marginTop: 13 }}
                                        fit='cover'
                                        width={40}
                                        height={40}
                                    />
                                }
                                description={comment.comment}
                            >
                                {comment.business.nickname??'匿名用户'}
                            </React.UI.List.Item>
                        ))}
                    {/* </React.UI.List> */}
                </div>
                <div  className="comment">
                    <div className="title">地址</div>
                    <TMap mapKey="433BZ-HMJCJ-X4FFY-XSK3K-O2KP7-GEBBY" version="1.exp" center={{ lat: 22.941232653384144, lng: 113.36653042460631 }}/>
                </div>

                <div className="notesin">
                    <div className="title">预订须知</div>
                    <div className="item">
                        <span className="tips">预订房型：</span>
                        <span>{room.name}</span>
                    </div>
                    <div className="item">
                        <span className="tips">入离时间：</span>
                        <span>15:00 后入住，12:00 前退房</span>
                    </div>
                    <div className="item">
                        <span className="tips">房间数量：</span>
                        <span>{room.total}</span>
                    </div>
                </div>
            </div>

            <div className="foot-bar">
                <div className="price">
                    ￥{room.price}
                </div>
                <React.UI.Button onClick={confirm} color='primary' disabled={room.state ? false : true}>立即预定</React.UI.Button>
            </div>
        </>
    )
}

export default Component