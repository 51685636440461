import React from 'react'
import '@/assets/css/order_info.css'

const Info = () => {
    //接收跳转参数
    const [searchParams] = React.Router.useSearchParams()

    const [visible, setVisible] = React.useState(false)

    let [orderid, SetOrderid] = React.useState(searchParams.get('orderid') ? searchParams.get('orderid') : 0);

    let [order, SetOrder] = React.useState({
        id: 0,
        code: '',
        oprice: 0,
        price: 0,
        status: '',
        status_text: '',
        thumb_text: '',
        createtime_text: '',
        guest_list: [],
        starttime_info: '',
        endtime_info: '',
        night_text: 0,
        business: {
            mobile: ''
        },
        room: {
            name: '',
            thumb_text: '',
            flag_text: [],
        },
    })

    React.useEffect(() => {
        info()
    }, [])

    //订单详情查询
    const info = async () => {
        var result = await React.HTTP.post('/order/info', { busid: React.Business.id, orderid: orderid })
        if (result.code == 0) {
            React.error(result.msg);
            return false;
        } else {
            SetOrder(result.data);
            return false;
        }
    }

    //退房
    const checkOut = () => {
        React.UI.Dialog.confirm({
            content: '是否提交退房申请',
            onConfirm: async () => {
                var result = await React.HTTP.post('/order/checkout', { busid: React.Business.id, orderid: orderid })
                if (result.code == 0) {
                    React.error(result.msg, () => { });
                    return false;
                } else {
                    SetOrder(result.data);
                    React.success(result.msg, () => { });
                    return false;
                }
            },
        })

    }

    //评价
    const comment = async (value: any) => {
        if (value.comment == '') {
            React.error('评论内容不能为空', () => { });
            return false;
        }
        value.orderid = orderid
        value.busid = React.Business.id;

        var result = await React.HTTP.post('/order/comment', value)
        if (result.code == 0) {
            React.error(result.msg, () => { });
            return false;
        } else {
            SetOrder(result.data);
            setVisible(false)
            React.success(result.msg, () => { });
            return false;
        }
    }

    return (
        <>
            <div className='top'>
                <React.UI.NavBar
                    back='返回'
                    onBack={() => React.Navigate(-1)}
                >订单详情</React.UI.NavBar>
            </div>

            <div className="order_info">
                {order.status == '3' &&
                    <div className="info_title">
                        <h3>待评价</h3>
                        <p>感谢您的光临，请给我们打个分吧</p>
                        <React.UI.Button className="info_rate" onClick={() => { setVisible(true) }}>立即评价</React.UI.Button>
                        <React.UI.CenterPopup visible={visible} onMaskClick={() => { setVisible(false) }} >
                            <div className='myContent'>
                                <React.UI.Form
                                    layout='horizontal'
                                    onFinish={comment}
                                    footer={
                                        <React.UI.Button block type='submit' color='primary' size='large'>确认评价</React.UI.Button>
                                    }
                                >
                                    <React.UI.Form.Item name='comment'>
                                        <React.UI.TextArea
                                            placeholder='请输入您的评价'
                                            showCount
                                            maxLength={50}
                                        />
                                    </React.UI.Form.Item>

                                </React.UI.Form>
                            </div>
                        </React.UI.CenterPopup>
                    </div>
                }
                <div className="info_content">
                    <div className="content_title">订单信息：</div>
                    <div className="content_item">
                        <p>订单状态</p>
                        <div>{order.status_text}</div>
                    </div>
                    <div className="content_item">
                        <p>订单单号</p>
                        <div>{order.code}</div>
                    </div>
                    <div className="content_item">
                        <p>订单原价</p>
                        <div className="content_price">￥{order.oprice}</div>
                    </div>
                    <div className="content_item">
                        <p>实际支付</p>
                        <div className="content_price">￥{order.price}</div>
                    </div>
                    <div className="content_item">
                        <p>下单时间</p>
                        <div>{order.createtime_text}</div>
                    </div>
                    <div className="content_item">
                        <p>入住人</p>
                        <div>{order.guest_list && order.guest_list.map((guest: any, index: any) => <span key={index}> {guest.nickname} </span>)}</div>
                    </div>
                    <div className="content_item">
                        <p>联系电话</p>
                        <div>{order.business.mobile}</div>
                    </div>
                    <div className="content_item">
                        <p>入住时间</p>
                        <div>{order.starttime_info} - {order.endtime_info} 共 {order.night_text} 晚</div>
                    </div>
                </div>
                {order.status == '1' &&
                    <div className="info_footer">
                        <React.UI.Button color='primary' fill='solid' onClick={checkOut}>我要退房</React.UI.Button>
                    </div>
                }
                {order.status == '-3' &&
                    <div className="info_footer">
                        <React.UI.Button color='primary' fill='solid' onClick={checkOut}>重新申请退房</React.UI.Button>
                    </div>
                }
                <div className="info_footer">
                    <div className="content_title">房屋信息：</div>
                    <p>{order.room.name}</p>
                    <div className="detail">
                        <div className="thumb">
                            <img src={order.room.thumb_text} alt="" />
                        </div>
                        <div className="right">
                            <div className="tips">
                                {order.room.flag_text && order.room.flag_text.map((flag: any, index: any) => <span key={index}> {flag} </span>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Info
