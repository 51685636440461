import Menu from '@/components/Common/Menu'
import React from 'react';
import '@/assets/css/index.css'
// import '@/assets/css/swiper-bundle.min.css'

const Home = () => {
    let [list, SetList] = React.useState([])

    let [keywords, SetWords] = React.useState('')
    let [page, SetPage] = React.useState(1)
    let [room, SetRoom] = React.useState([])
    let [hasMore, SethasMore] = React.useState(true)
    let ref = React.useRef(null)

    React.useEffect(() => {
        CouponData()
        // onRefresh()
    }, [])

    const CouponData = async () => {
        var result = await React.HTTP.post('/coupon/banner', {})

        if (result.code == 1) {
            SetList(result.data)
        }
    }

    //优惠券组件
    const CouponItem = list.map((item: any, key) => {
        return (
            <React.UI.Swiper.Item key={key} onClick={() => React.Navigate(`/coupon/info?cid=${item.id}`)} className='banner'>
                <React.UI.Image src={item.thumb_text} width={'100%'} height={'100%'} fit='fill' />
            </React.UI.Swiper.Item>
        )
    })

    //搜索方法
    const search = async (e: any) => {
        //阻止默认事件
        e.preventDefault();

        onRefresh();

        return false;

    }

    // 下拉刷新的方法
    const onRefresh = async () => {
        SetPage(1)
        SetRoom([])
        SethasMore(true)
        // await RoomData()
    }

    //上拉加载
    const loadMore = async () => {
        SetPage(page + 1)
        await RoomData()
    }

    //请求数据
    const RoomData = async () => {
        var result = await React.HTTP.post('/room/index', { page, keywords, busid: React.Business.id ?? 0 })

        SethasMore(false)

        if (result.code == 0) {
            SethasMore(false)
            return false
        } else {
            if (result.data.length < 10) {
                SethasMore(false)
            }

            if (result && result.data.length > 0) {
                SethasMore(true)
                SetRoom(room.concat(result.data))
            }
        }
    }

    const ClickCollect = async (roomid: any, key: any) => {
        if (!React.Business.id) {
            React.error('请先登录', () => { });
            return false;
        }
        var result = await React.HTTP.post('/room/collect', { roomid, busid: React.Business.id })
        if (result.code == 1) {
            if (room.length > 0) {
                // room[key].collect = !room[key].collect;
                // SetRoom(room);
                onRefresh()
            }
        }

    }

    //收藏组件
    const Collect = (collect: any, roomid: any, key: any) => {
        if (collect) {
            return <React.Icon.HeartFill fontSize={30} color='red' onClick={() => ClickCollect(roomid, key)} />
        } else {
            return <React.Icon.HeartOutline fontSize={30} color='#ccc' onClick={() => ClickCollect(roomid, key)} />
        }
    }

    return (
        <>
            {/* 导航 */}
            <div className="top">
                <div>首页</div>
            </div>

            {/* 轮播图的优惠券 */}
            <React.UI.Swiper loop='true' autoplay='true' autoplayInterval='3000'>
                {CouponItem}
            </React.UI.Swiper>

            <form className="hotel_search" onSubmit={search.bind(this)}>
                <input type="text" value={keywords} onChange={(e) => { SetWords(e.target.value) }} placeholder="请输入关键词搜索" />
                {/* <div className="screen_icon">
                    <img src="./assets/images/screen.png" alt="" />
                </div> */}
            </form>

            <React.UI.PullToRefresh onRefresh={onRefresh}>
                <div className="hotellist">
                    {room.length > 0 && room.map((item: any, key) =>
                        <div className="item" key={key}>
                            <a>
                                {/* <div className="type_name">新房特惠</div> */}
                                <div className="collect">
                                    {/* <img src="./assets/images/heart.png" alt=""/> */}
                                    {Collect(item.collect, item.id, key)}
                                </div>
                                <div className="images" onClick={() => {React.Navigate(`/room/info?rid=${item.id}`)}}>
                                    <div className="swipers">
                                        <img src={item ? item.thumb_text : ''} alt="" />
                                    </div>
                                    <div className="title">{item.name ?? '房间名称'}</div>
                                </div>

                                <div>
                                    <div className="item_top">
                                        {item && item.flag_text && item.flag_text.map((flag: any, index: any) => <span key={index}>{flag}</span>)}
                                    </div>

                                    <div className="item_bot">
                                        <div className="font">
                                            <span className="price">￥{item ? item.price : 0}/晚</span>
                                        </div>
                                        <div className="btn">立即预定</div>
                                    </div>
                                </div>
                            </a>
                        </div>)
                    }
                </div>
            </React.UI.PullToRefresh>
            <React.UI.InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0} />
            <Menu />
        </>
    )
}

export default Home