//路由模板加载标签
import { Outlet } from "react-router-dom";

import Index from '@/components/Order/Index'
import Info from '@/components/Order/Info'
import Confirm from '@/components/Order/Confirm'

//路由列表
const list = [
  {
    path: 'index',
    component: Index,
    auth: true
  },
  {
    path: 'info',
    component: Info,
    auth: true
  },
  {
    path: 'confirm',
    component: Confirm,
    auth: true
  }
]

//父组件结构
const Layout = () => <Outlet />

const RouterList = [
  {
    path: '/order',
    component: Layout,
    children: list
  }
]

export default RouterList