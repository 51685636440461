import React from 'react';

import '@/assets/css/coupon-info.css'


const Component = () =>
{
    //接收跳转参数
    const [searchParams] = React.Router.useSearchParams()

    let [cid, SetCID] = React.useState(searchParams.get('cid') ? searchParams.get('cid') : 0)

    let [coupon, SetCoupon] = React.useState({
        title:'',
        rate:1,
        total:0,
        createtime_text:'',
        endtime_text:'',
        thumb_text: '',
        receive: true,
    })

    let [receive, SetReceive] = React.useState([])

    React.useEffect(() => {
        CouponData()
    }, [])

    //请求优惠券详情
    const CouponData = async () =>
    {
        //组装数据
        var data = {
            cid:cid,
            busid: React.Business.id
        }
        var result = await React.HTTP.post("/coupon/info", data)

        if(result.code == 0)
        {
            React.error(result.msg)
            return false
        }

        SetCoupon(result.data.coupon)
        SetReceive(result.data.receive)

    }

    //领取优惠券
    const CounponReceive = async () =>
    {
        if(!React.Business.id)
        {
            React.error('请先登录', () => {})
            return false
        }


        //组装数据
        var data = {
            cid,
            busid: React.Business.id
        }

        //发请求
        var result = await React.HTTP.post('/coupon/receive', data)

        if(result.code == 0)
        {
            React.error(result.msg, () => {})
        }else
        {
            CouponData()
            React.success(result.msg, () => {})
        }
    }

    const Items = receive.map((item:any, key) => 
        <React.UI.Swiper.Item key={key}>
            <React.UI.NoticeBar 
                style={{'border':'0px'}} 
                content={`${item.business.nickname ? item.business.nickname : '匿名用户'} 在 ${item.createtime_text} 领取了优惠券`} 
                color='info' 
            />
        </React.UI.Swiper.Item>   
    )

    return (
        <>
            <React.UI.NavBar 
                style={{background: `rgb(55, 68, 134)`, color:'#fff'}}
                // back='返回' 
                onBack={()=>{React.Navigate(-1)}}
            >{coupon.title}</React.UI.NavBar>

            <React.UI.AutoCenter>
                <React.UI.Image src={coupon.thumb_text} width={'100%'} fit='fill' />
            </React.UI.AutoCenter>

            <React.UI.Swiper 
                autoplay={true}
                autoplayInterval={1000}  
                loop={true}
                indicator={() => false} 
                direction='vertical' 
                style={{ '--height': '40px', 'border':'0px' }}
            >
                {Items}
            </React.UI.Swiper>

            <div className="coupon_detail">
                <div className="coupon_info">
                    <div className="left">
                        <div className="left_top">
                            <div>
                                <span>{coupon.rate*10}</span>折
                            </div>
                            <div className="top_info">
                                <div>优惠券</div>
                                <div>COUPON</div>
                            </div>
                        </div>
                    </div>
                    <div className="receive">
                        {
                            coupon.receive ? 
                            <React.UI.Button size="small" disabled color='primary'>您已领取</React.UI.Button> : 
                            <React.UI.Button onClick={CounponReceive} size="small" color='primary'>领取</React.UI.Button>
                        }
                    </div>
                </div>
                <div className="coupon_prompt">
                    <div className="prompt_title">温馨提示：</div>
                    <div>
                        <span>•</span>
                        领取后 {coupon.createtime_text} 至 {coupon.endtime_text} 有效
                        </div>
                    <div><span>•</span>仅限量{coupon.total}张，赶快领取！</div>
                </div>
            </div>
        </>
    )
}

export default Component