import React from 'react'
import '@/assets/css/login.css'


const Register = () => {
    //实例化钩子函数
    const navigate = React.Router.useNavigate()

    const register = async (values: any) => {
        var result: any = await React.HTTP.post('/business/register', values)

        if (result.code) {
            React.UI.Toast.show({
                icon: 'success',
                content: result.msg,
                afterClose: () => {
                    // navigate('/business/login')
                    return false
                }
            })

        } else {
            React.UI.Toast.show({
                icon: 'fail',
                content: result.msg
            })

            return false
        }
    }

    return (
        <>
            <div className='top'><React.UI.NavBar back='首页' onBack={() => React.Navigate('/')}>注册</React.UI.NavBar></div>
            <div className="login">
                <h1 className='title'>期待您的到来</h1>
                <React.UI.Form
                    initialValues={{ mobile: '', password: '' }}
                    onFinish={register}
                    name='register'
                    footer={
                        <React.UI.Button block type='submit' color='primary' size='large'>
                            注册
                        </React.UI.Button>
                    }
                >
                    <React.UI.Form.Item name='mobile' label='手机号' rules={[{ required: true }, { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不对' }]}>
                        <React.UI.Input placeholder='请输入手机号' />
                    </React.UI.Form.Item>

                    <React.UI.Form.Item name='password' label='密码' rules={[{ required: true }]}>
                        <React.UI.Input type="password" placeholder='请输入密码' />
                    </React.UI.Form.Item>

                </React.UI.Form>
                <React.Router.Link className='LRlink' to="/business/login">已有账号?前往登录</React.Router.Link>
            </div>
        </>
    )
}

export default Register