//路由模板加载标签
import { Outlet } from "react-router-dom";

import Index from '@/components/Business/Index'
import Register from '@/components/Business/Register'
import Login from '@/components/Business/Login'
import Profile from '@/components/Business/Profile'
import Order from '@/components/Order/Index'
import Collect from '@/components/Business/Collect'
import Hotelinfo from '@/components/Business/Hotelinfo'

//路由列表
const list = [
  {
    path: 'index',
    component: Index,
    auth: true
  },
  {
    path: 'register',
    component: Register,
  },
  {
    path: 'login',
    component: Login,
  },
  {
    path: 'profile',
    component: Profile,
    auth: true
  },
  {
    path:'collect',
    component:Collect,
    auth:true
  },
  {
    path:'hotelinfo',
    component:Hotelinfo
  }
]

//父组件结构
const Layout = () => <Outlet />

const RouterList = [
  {
    path: '/business',
    component: Layout,
    children: list
  }
]

export default RouterList