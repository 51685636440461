import React from 'react'
import { TMap } from '@map-component/react-tmap';
import '@/assets/css/hotel-info.css'

const Hotelinfo = () => {
    let [list, SetList] = React.useState([])

    React.useEffect(() => {
        CouponData()
        // onRefresh()
    }, [])

    const CouponData = async () => {
        var result = await React.HTTP.post('/coupon/banner', {})

        if (result.code == 1) {
            SetList(result.data)
        }
    }

    const CouponItem = list.map((item: any, key) => {
        return (
            <React.UI.Swiper.Item key={key} onClick={() => React.Navigate(`/coupon/info?cid=${item.id}`)} className='banner'>
                <React.UI.Image src={item.thumb_text} width={'100%'} height={'100%'} fit='fill' />
            </React.UI.Swiper.Item>
        )
    })
    return (
        <>
            <div className='top'>
                <React.UI.NavBar
                    back='返回'
                    onBack={() => React.Navigate(-1)}
                >门店详情</React.UI.NavBar>
            </div>
            {/* <!-- 轮播 --> */}
            <React.UI.Swiper loop='true' autoplay='true' autoplayInterval='3000'>
                {CouponItem}
            </React.UI.Swiper>

            <div className="hotelinfo">
                <div className="hotelinfo_title">
                    维也纳国际酒店(广州番禺市桥中心国际店)
                </div>
                <div className="hotelinfo_address">
                    广东省广州市番禺区市桥街大北路118号
                </div>
                <div className="map" >
                    <TMap mapKey="433BZ-HMJCJ-X4FFY-XSK3K-O2KP7-GEBBY" version="1.exp" center={{ lat: 22.941232653384144, lng: 113.36653042460631 }} />
                </div>
                <div className="hotelinfo_desc">
                    <div className="desc_title">简介</div>
                    <div>酒店的简介</div>
                </div>
                <div className="hotelinfo_desc">
                    <div className="desc_title">详情</div>
                    <div>酒店的详情</div>
                </div>
            </div>
        </>
    )
}

export default Hotelinfo