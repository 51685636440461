import React from 'react'
import '@/assets/css/coupon.css'
import { PullStatus } from 'antd-mobile/es/components/pull-to-refresh'

const tabItems = [
    { key: '0', title: '全部' },
    { key: '1', title: '未使用' },
    { key: '2', title: '已使用' },
    { key: '3', title: '未生效' },
    { key: '4', title: '已过期' },
]

const statusRecord: Record<PullStatus, string> = {
    pulling: '用力拉',
    canRelease: '松开吧',
    refreshing: '玩命加载中...',
    complete: '好啦',
}

const Index = () => {
    //默认选项卡
    var [activeIndex, setActiveIndex] = React.useState(0)

    var [status, SetStatus] = React.useState('0')
    // var status = 'all'
    // var page = 1
    var [page, SetPage] = React.useState(1)
    var [list, SetList] = React.useState([])
    var [hasMore, SethasMore] = React.useState(true)
    // var hasMore=true;
    var ref: any = React.useRef(null);

    React.useEffect(() => {
        onRefresh()
    }, [status])
    React.useEffect(() => {
        ref.list = list
    }, [list])
    React.useEffect(() => {
        ref.hasMore = hasMore; console.log(hasMore);
    }, [hasMore])

    // 下拉刷新
    const onRefresh = async () => {
        SetPage(page => page = 1)
        // page = 1
        SetList([])
        SethasMore(true)
        // hasMore=true;
        await ListData()
    }

    //上拉加载
    const loadMore = async () => {
        SetPage(page => page + 1)
        await ListData()
    }

    //请求数据
    const ListData = async () => {
        //组装数据
        var data = {
            page: page,
            status: status,
            busid: React.Business.id
        }

        var result = await React.HTTP.post('/coupon/index', data)

        if (result.code == 0) {
            //没有更多数据
            SethasMore(false)
            // hasMore=false
            // React.error(result.msg, () => { })
            return false
        }

        // SetPage(page + 1)
        console.log(page);

        if (result.data.length < 10) {
            SethasMore(false)
        }

        SetList(list.concat(result.data))
    }


    const clickMenu = async (key: any) => {
        SetStatus(key)
        SetPage(1)
        SetList([])
        SethasMore(true)
        // await ListData()
    }

    const Button = (status:any) => {
        if (status == '1') {
            return (<button className="right_btn1" >未使用</button>)
        } else {
            return (<button className="right_btn2" disabled>已使用</button>)
        }
    }


    return (
        <>
            <div className='top'><React.UI.NavBar onBack={() => React.Navigate(-1)}>我的优惠券</React.UI.NavBar></div>

            <div className='ordermenu'>
                <React.UI.Tabs
                    activeKey={tabItems[activeIndex].key}
                    onChange={async (key: any) => {
                        const index = tabItems.findIndex(item => item.key === key)
                        setActiveIndex(index);
                        await clickMenu(key);
                    }}
                >
                    {tabItems.map(item => (
                        <React.UI.Tabs.Tab title={item.title} key={item.key} />
                    ))}
                </React.UI.Tabs>
            </div>
            <div className="order_list">
                {/* 下拉刷新 */}
                <React.UI.PullToRefresh
                    onRefresh={onRefresh}
                    renderText={(status: PullStatus) => {
                        return <div>{statusRecord[status]}</div>
                    }}
                >
                    <div className="coupon_list">
                        <React.UI.List >
                            {list.map((item: any, key) => (
                                <React.UI.List.Item key={key} arrow={false} >
                                    <div className="coupon_item">
                                        <div className="item_content">
                                            <div className='a' onClick={() => React.Navigate(`/coupon/info?cid=${item.activity.id}`)}>
                                                <div className="left">
                                                    {item.activity.rate * 10}<span>折</span>
                                                </div>
                                                <div className="right">
                                                    <div>{item.activity.title}</div>
                                                    {/* <div>订单满<span>100元</span>打<span>8折</span></div> */}
                                                    <div>每人限领1张</div>
                                                    <div>领取后活动期间内有效</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item_btn">
                                            {Button(item.status)}
                                        </div>
                                    </div>
                                </React.UI.List.Item>
                            ))}
                        </React.UI.List>
                    </div>

                    <React.UI.InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={0} />
                </React.UI.PullToRefresh>
            </div>
        </>
    )
}

export default Index
