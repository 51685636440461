import React from 'react'
import '@/assets/css/login.css'


const Login = () => {
    //实例化钩子函数
    // console.log(React.Router);

    const navigate = React.Router.useNavigate()

    const login = async (values: any) => {
        var result: any = await React.HTTP.post('/business/login', values)

        if (result.code) {
            React.UI.Toast.show({
                icon: 'success',
                content: result.msg,
                afterClose: () => {
                    React.Cookie.save('business', result.data, { path: '/' })
                    navigate('/business/index')
                    return false
                }
            })

        } else {
            React.UI.Toast.show({
                icon: 'fail',
                content: result.msg
            })

            return false
        }
    }

    return (
        <>
            <div className='top'><React.UI.NavBar back='首页' onBack={() => React.Navigate('/')}>登录</React.UI.NavBar></div>
            <div className="login">
                <h1 className='title'>期待您的到来</h1>
                <React.UI.Form
                    initialValues={{ mobile: '', password: '' }}
                    onFinish={login}
                    name='login'
                    footer={
                        <React.UI.Button block type='submit' color='primary' size='large'>
                            登录
                        </React.UI.Button>
                    }
                >
                    <React.UI.Form.Item name='mobile' label='手机号' rules={[{ required: true }, { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不对' }]}>
                        <React.UI.Input placeholder='请输入手机号' />
                    </React.UI.Form.Item>

                    <React.UI.Form.Item name='password' label='密码' rules={[{ required: true }]}>
                        <React.UI.Input type="password" placeholder='请输入密码' />
                    </React.UI.Form.Item>

                </React.UI.Form>
                <React.Router.Link className='LRlink' to="/business/register">还没有账号?前往注册</React.Router.Link>
            </div>
        </>
    )
}

export default Login